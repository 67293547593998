import React from "react";

const Schedule = () => {
  return (
    <section
      className="work-process-section ptb-100 gray-light-bg"
      id="schedule"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-9">
            <div className="section-heading text-center">
              <strong className="color-secondary">When to Join?</strong>
              <h2> Hackathon Schedule </h2>
              <span className="animate-border mr-auto ml-auto mb-4"></span>
            </div>
          </div>
        </div>
        <div className="flexxx">
          <div className="row">
            <div className="col-md-12 col-lg-5 process-width">
              <div className="process-box process-left">
                <div className="row">
                  <div className="col-md-5">
                    <div className="process-step-number">
                      <strong>22nd April -  22nd July</strong>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <h5>Registration Starts</h5>
                    <p>
                    Public Relations is recruiting participants for the project.{" "}
                    </p>
                  </div>
                </div>
                <div className="process-line-r"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-5 process-width">
              <div className="process-box process-left">
                <div className="row">
                  <div className="col-md-5">
                    <div className="process-step-number">
                      <strong>3rd Aug</strong>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <h5>Training Activities</h5>
                    <p>Organizing a training event (hosted by the company). </p>
                  </div>
                </div>
                <div className="process-line-r"></div>
              </div>
            </div>
          </div>
        </div>


        <div className="flexxx">
        <div className="row">
            <div className="col-md-12 col-lg-5 process-width">
              <div className="process-box process-left">
                <div className="row">
                  <div className="col-md-5">
                    <div className="process-step-number">
                      <strong>2nd Sep</strong>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <h5>Submit a proposal</h5>
                    <p>
                    The student proceeds to submit a proposal.{" "}
                    </p>
                  </div>
                </div>
                <div className="process-line-l"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-5 process-width">
              <div className="process-box process-left">
                <div className="row">
                  <div className="col-md-5">
                    <div className="process-step-number">
                      <strong>9th Sep</strong>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <h5>Announcing the team list</h5>
                    <p>Announcing the teams selected to participate in the event.</p>
                  </div>
                </div>
                <div className="process-line-r"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="flexxx">
          <div className="row">
            <div className="col-md-12 col-lg-5 process-width">
              <div className="process-box process-left">
                <div className="row">
                  <div className="col-md-5">
                    <div className="process-step-number">
                      <strong>13th - 15th Sep</strong>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <h5>Hackathon Starts</h5>
                    <p>
                    Hackathon & Pitching event.<br />{" "}
                    </p>
                  </div>
                </div>
                <div className="process-line-l"></div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default Schedule;
