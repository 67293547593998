import React from "react";
import Faq from "react-faq-component";


const data = {
  title:"",
  rows: [
    {
      title:"What is a NU x EXTOSOFT Hacks?",
      content:"NU x EXTOSOFT Hacks is a 48-hour hackathon organized by Extosoft, Naresuan University in which all participants can sharpen their skills and connect with people to grow together."
    },

    {
      title:"Who can attend?",
      content:"NU x EXTOSOFT Hacks is open to only NU students."
    },

    {
      title:"I am first time hacker, what should I do?",
      content:"No worries, we love to welcome first-time hackers and start their hacking journey from a hackathon. We have some awesome workshops and events which will help you get started with hackathons and hacking in general. Join our discord server for more details."
    },

    {
      title:"How team formation works?",
      content:"You can form a team with 5 members in it at maximum. Individual participation is allowed."
    }
  
  ]
}


const FAQS = () => {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://apply.devfolio.co/v2/sdk.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
}, []);
  return (
    <section className="promo-section ptb-100" id="faq">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9 col-lg-8">
            <div className="section-heading mb-3 text-center">
              <h2>Frequently Asked Questions</h2>
              <span className="animate-border mr-auto ml-auto mb-4"></span>
            </div>
              <Faq data={data} />
          </div>
        </div>


        <br/><br/><br/>
        
      </div>
    </section>
  );
};

export default FAQS;
