import React from 'react'
import "./timeline.css";

function TimeLine1() {
  return (

    <div className='ptb-100' id="timeline">
      <div className="section-heading text-center mb-5">

        <h2> Timeline</h2>
        <span className="animate-border mr-auto ml-auto mb-4"></span>
      </div>
      <div class="wrap">
        <div class="center-line">
          {/* <a href="#" class="scroll-icon"><i class="fas fa-caret-up"></i></a> */}
        </div>
        <div class="row row-1">
          <section>
            <i class="icon fas fa-desktop"></i>
            <div class="details">
              <span class="title">Software Testing Methodology</span>
              <span>9.00AM - 10.00AM</span>
            </div>
            {/* <p className="timeline_text" style={{fontSize: "14px"}}>Lorem ipsum dolor sit ameters consectetur adipisicing elit. Sed qui veroes praesentium maiores, sint eos vero sapiente voluptas debitis dicta dolore.</p>
        */}
          </section>
        </div>
        <div class="row row-2">
          <section>
            <i class="icon fas fa-chart-area"></i>
            <div class="details">
              <span class="title">Performance Test with JMeter Tool</span>
              <span>10.00AM - 11.00AM</span>
            </div>
            {/*<p  className="timeline_text" style={{fontSize: "14px"}}>Lorem ipsum dolor sit ameters consectetur adipisicing elit. Sed qui veroes praesentium maiores, sint eos vero sapiente voluptas debitis dicta dolore.</p>
       */}
          </section>
        </div>
        <div class="row row-1">
          <section>
            <i class="icon fa fa-robot"></i>
            <div class="details">
              <span class="title">Performance Test with AI - Colab</span>
              <span>11.00AM - 12.00PM</span>
            </div>
            <p className="timeline_text ml-5" style={{ fontSize: "14px" }}><span className='fas fa-angle-right'></span>  Create Test Case</p>
            <p className="timeline_text ml-5" style={{ fontSize: "14px" }}><span className='fas fa-angle-right'></span>  Create Test Data</p>
            <p className="timeline_text ml-5" style={{ fontSize: "14px" }}><span className='fas fa-angle-right'></span>  Create Test Script JMeter</p>
            <p className="timeline_text ml-5" style={{ fontSize: "14px" }}><span className='fas fa-angle-right'></span>  Execution Scenario</p>
            <p className="timeline_text ml-5" style={{ fontSize: "14px" }}><span className='fas fa-angle-right'></span>  Create Result Analysis</p>

          </section>
        </div>
        <div class="row row-2">
          <section>
            <i class="icon fas fa-desktop"></i>
            <div class="details">
              <span class="title">Fine-tuning a LLM for Monitoring </span>
              <span>1.00PM - 2.00PM</span>
            </div>
            <p className="timeline_text" style={{ fontSize: "14px" }}>System, Application and Log</p>

          </section>
        </div>
        <div class="row row-1">
          <section>
            <i class="icon fas fa-code"></i>
            <div class="details">
              <span class="title">Fine-tuning a LLM for forecast produciton infrastructure</span>
              <span>2.00PM - 3.00PM</span>
            </div>
            {/* <p className="timeline_text" style={{fontSize: "14px"}}>Session on Complete Interview Preperation by GeeksForGeeks on 11.30 AM Onwards.</p> */}

          </section>
        </div>
        <div class="row row-2">
          <section>
            <i class="icon 	fas fa-microchip"></i>
            <div class="details">
              <span class="title">Docker technology</span>
              <span>3.00PM - 4.00PM</span>
            </div>
            <p className="timeline_text" style={{ fontSize: "14px" }}>Build, Run and Deploy</p>

          </section>
        </div>

      </div>
    </div>
  );
}

export default TimeLine1;