import React from "react";

const Prizes = () => {
  return (
    <section id="prizes" className="promo-section ptb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-8">
            <div className="section-heading text-center mb-5">
              <strong className="color-secondary"></strong>
              <h2>Hackathon Prizes</h2>
              <span className="animate-border mr-auto ml-auto mb-4"></span>
              <p className="lead "></p>
              <div className="prize ">
                <span className="color-secondary">
                  <i className="fa-solid fa-bolt fixi"></i>
                </span>{" "}
                <h1 className="pb-4 ">Up to 100,000฿</h1>
                <span className="color-secondary">
                  <i className="fa-solid fa-bolt fixi"></i>
                </span>{" "}
              </div>


            </div>
          </div>

        </div>

       
      </div>
    </section>
  );
};

export default Prizes;
