import React, { useEffect } from "react";
import {
  TeamOrganiser
} from "../data/teamdetails";

function TeamMembers(props) {
  return props.map((s, k) => <Team1 info={s} />);
}

const Team1 = ({ info }) => {
  const { role, Name, img, linkedin } = info;
  return (
    <div
      style={{ paddingBottom: "0px !important" }}
      className="slide staff-member my-md-3 my-lg-3 my-sm-0"
    >
      <div
        className="card teamClass text-center pt-2"
        style={{ background: "rgb(26,18,53)" }}
      >
        <div className="m-auto" style={{ width: "120px", height: "120px", overflow: "hidden", borderRadius: "50%", position: "relative" }}>
          <img src={img} alt="team image" style={{ width: "100%", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
        </div>


        <div className="card-body">
          <h6 className="teacher mb-0">
            <b style={{ color: "white" }}>{Name}</b>
          </h6>
          <span style={{ color: "white" }}>{role}</span>
          <ul className="list-inline social">
          </ul>
        </div>
      </div>
    </div>
  );
};

const Team = () => {
  const delay = 2500;
  const [index1, setIndex1] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setIndex1((prevIndex) =>
        prevIndex === TeamOrganiser[0].length - 1 ? 0 : prevIndex + 1
      );
    }, delay);

    return () => {
      resetTimeout();
    };
  }, [index1]);

  return (
    <div className="container">
      <div style={{ margin: "2rem" }} className="row justify-content-center">
        <div className="col-md-8 col-lg-8">
          <div className="section-heading text-center mb-5">
            <strong className="color-secondary"></strong>
            <h2>Organisers Team</h2>
            <span className="animate-border mr-auto ml-auto mb-4"></span>
            <p className="lead"></p>
          </div>
        </div>
      </div>

      <div className="slideshow">
        <div
          className="slideshowSlider"
          style={{ transform: `translate3d(${-index1 * 100}%, 0, 0)` }}
        >
          {TeamOrganiser.map(TeamMembers)}
        </div>
      </div>
      <br></br>
      <br></br>
    </div>
  );
};

export default Team;
