import React from "react";

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-top gradient-bg">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-9">
              <div className="row footer-top-wrap">
                <div className="col-md-4 col-sm-6">
                  <div className="footer-nav-wrap text-white">
                   
                    <img
                      src="img/boy2.svg"
                      alt="boy1"
                      className="img-fluid about-single-img imgimg "
                      style={{ transform: 'scaleX(-1)' }}
                    />


                  </div>
                </div>
                <div className="col-md-3 col-sm-6  ">
                  <div className="footer-nav-wrap text-white">
                    <h4 className="text-white"> Useful Links </h4>
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link" href="#challenges">
                          {" "}
                          Themes{" "}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#schedule">
                          {" "}
                          Schedule
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#faq">
                          {" "}
                          FAQ's
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#speakersAndJudges">
                          {" "}
                          Speakers{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-5 col-sm-6">
                  <div className="footer-nav-wrap text-white">
                    <script
                      async
                      src="https://platform.twitter.com/widgets.js"
                      charSet="utf-8"
                    ></script>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="footer-bottom gray-light-bg py-2">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5 col-lg-5">
              <p className="copyright-text pb-0 mb-0">
                Copyrights © 2024. All rights reserved by &nbsp;
                <a>NU x EXTOSOFT Hacks 1.0</a>
              </p>
            </div>
            <div className="col-md-7 col-lg-6 d-none d-md-block d-lg-block">
              <ul className="list-inline policy-nav text-right social-list">
                <li className="list-inline-item">
                  
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
