import React from "react";

const Sponsers = () => {
    return (
        <div id="sponsers" className="client-section ptb-100">
            <div className="container" id="#sponsers">
                <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-9">
                        <div className="section-heading text-center">
                            <h2> Our Sponsors </h2>
                            <span className="animate-border mr-auto ml-auto mb-4"></span>
                        </div>
                    </div>
                </div>
                <br />

                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-12">
                        <div className="section-heading text-center">
                            <h3 className="color-secondary" style={{ marginTop: "20px" }}>
                                {" "}
                                Event Sponsor{" "}
                            </h3>

                            <img
                                src="img/sponsors/extosoft.jpg"
                                style={{
                                    height: "80px",
                                    width: "160px",
                                    margin: "30px 50px",
                                    borderBottom: "0px",
                                }}
                            />
                        </div>
                        <hr />
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-12">
                        <div className="section-heading text-center">
                            <h3 className="color-secondary" style={{ marginTop: "20px" }}>
                                {" "}
                                Collaborator Sponsors{" "}
                            </h3>
                            <img
                                src="img/sponsors/Engineer.png"
                                style={{
                                    height: "100px",
                                    // width: "140px",
                                    margin: "30px 50px",
                                    borderBottom: "0px",
                                }}
                            />
                            <img
                                src="img/sponsors/Science.jpg"
                                style={{
                                    height: "100px",
                                    // width: "140px",
                                    margin: "30px 50px",
                                    borderBottom: "0px",
                                }}
                            />

                            <img
                                src="img/sponsors/BEC.png"
                                style={{
                                    height: "100px",
                                    // width: "140px",
                                    margin: "30px 50px",
                                    borderBottom: "0px",
                                }}
                            />
                            <img
                                src="img/sponsors/SciP.png"
                                style={{
                                    height: "100px",
                                    // width: "140px",
                                    margin: "30px 50px",
                                    borderBottom: "0px",
                                }}
                            />
                            <img
                                src="img/sponsors/Division.png"
                                style={{
                                    height: "100px",
                                    // width: "140px",
                                    margin: "30px 50px",
                                    borderBottom: "0px",
                                }}
                            />
                            <img
                                src="img/sponsors/Midfielder.png"
                                style={{
                                    height: "100px",
                                    // width: "140px",
                                    margin: "30px 50px",
                                    borderBottom: "0px",
                                }}
                            />
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sponsers;
