const TeamOrganiser = [
  [
    {
      Name: "Nattapon Glarharn",
      role: "Extosoft, CEO",
      img: "img/team/Nattapon Glarharn.jpg",
    },
    {
      Name: "Thawatchai Singngam",
      role: "Extosoft Co-Founder, Senior DevOps PTTEP",
      img: "img/team/Thawatchai Singngam.jpg",
    },
    {
      Name: "Pakadon Kumpehra",
      role: "Reclover, CEO",
      img: "img/team/Pakadon Kumpehra.jpg",
    },
    {
      Name: "Porjed Kuljittiyanee",
      role: "Reclover, Co-Founder, Senior DevOps",
      img: "img/team/Porjed Kuljittiyanee.jpg",
    },
    {
      Name: "Trithep Chantunyakorn",
      role: "Extosoft Co-Founder, Technical Consult",
      img: "img/team/Trithep Chantunyakorn.jpg",
    },
    {
      Name: "Kittimasak Wangsri",
      role: "Reclover, Co-Founder,  DevOps, Machine Learning, AI, Cloud",
      img: "img/team/Kittimasak Wangsri.jpg",
    },
    {
      Name: "Nacholnipa Nata",
      role: "Extosoft Co-Founder, Project Lead",
      img: "img/team/Nacholnipa Nata.jpg",
    },
    {
      Name: "Thiraphong KongKoed",
      role: "Extosoft QA",
      img: "img/team/Thiraphong KongKoed.jpg",
    },
    {
      Name: "Chanapa Chanthanyakorn",
      role: "Extosoft QA",
      img: "img/team/Chanapa Chanthanyakorn.jpg",
    },
    {
      Name: "Orawan Petkhiew",
      role: "Extosoft QA",
      img: "img/team/Orawan Petkhiew.jpg",
    },
    {
      Name: "Ratchadaporn Thongkow",
      role: "Extosoft QA",
      img: "img/team/Ratchadaporn Thongkow.jpg",
    },
    {
      Name: "Anuwat Somart",
      role: "Extosoft QA",
      img: "img/team/Anuwat Somart.jpg",
    },
    {
      Name: "Wongsathon Penglawan",
      role: "Extosoft QA",
      img: "img/team/Wongsathon Penglawan.jpg",
    },
    {
      Name: "Nattapon Klantapura",
      role: "Extosoft QA",
      img: "img/team/Nattapon Klantapura.jpg",
    },
    {
      Name: "Marlena Lunow",
      role: "Extosoft QA",
      img: "img/team/Marlena Lunow.jpg",
    },
    {
      Name: "Pattanan Boonmasueb",
      role: "Extosoft QA",
      img: "img/team/Pattanan Boonmasueb.jpg",
    },
    {
      Name: "Sineenat Seesung",
      role: "Extosoft QA",
      img: "img/team/Sineenat Seesung.jpg",
    },
    {
      Name: "Dechathon Thanasutthiphat",
      role: "Extosoft QA",
      img: "img/team/Dechathon Thanasutthiphat.jpg",
    },
    {
      Name: "Phurinat Kantopayao",
      role: "DevOps",
      img: "img/team/Phurinat Kantopayao.jpg",
    },
    {
      Name: "Wanchaloem Nanonchai",
      role: "Extosoft QA",
      img: "img/team/Wanchaloem Nanonchai.jpg",
    }
  ],
];


export {
  TeamOrganiser,
};
