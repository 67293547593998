import React from "react";
import "./hero.css";

const Hero = () => {
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apply.devfolio.co/v2/sdk.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section id="header-top-bar" className="ptb-70 gradient-bg">
      <div className="container mb-2">
        <div className="row align-items-center">
          <div className="col-md-12 col-lg-6">
            <div className="hero-slider-content text-white pt-5">
              <strong>13th - 15th September 2024 </strong>
              <h1 className="text-white mb-0">NU x EXTOSOFT Hacks 1.0</h1>
             

              <p className="lead mt-4">
                Get your creative hats on and join us on this incredible ride to
                build something out of the box.
              </p>

             
            </div>
          </div>
          <div className="col-md-12 col-lg-4 ml-5">
            <div className="pt-5 ml-5  ">
            <img
                src="img/boy1.svg"
                alt="boy1"
                className="img-fluid about-single-img imgimgimg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
