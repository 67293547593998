import React from "react";

const About = () => {
  return (
    <section className="about-us-section ptb-100 gray-light-bg " id="why">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <div className="about-us-img">
              <img
                src="img/about-bg.png"
                alt="about us"
                className="img-fluid about-single-img "
              />
              <img
                src="img/about-us-img-bg.svg"
                alt="about bg"
                className="about-us-bg"
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-6 ">
            <div className="about-us-content-wrap">
              <strong className="color-secondary">NU x EXTOSOFT Hacks Overview</strong>
              <h3>What is NU x EXTOSOFT Hacks?</h3>
              <span className="animate-border mb-4"></span>
              
              <p>
                NU x EXTOSOFT Hacks is a 48-hour hackathon organized by Extosoft, Naresuan University in which all participants can sharpen their skills and connect with people to grow together. The hackathon is designed to spark innovation, attract and educate new talent and create new channels for collaboration between performance and capacity professionals. We aim to spread the idea that programming is a useful skill that everyone can learn.
              </p>
              <div className="row mt-4">
                <div className="col-4">
                  <div className="counter-single">
                    <h2 className="mb-0 color-secondary">6</h2>
                    <strong>Themes </strong>
                  </div>
                </div>
                <div className="col-4 ">
                  <div className="counter-single">
                    <h2 className="mb-0 color-secondary">48+</h2>
                    <strong>
                      {" "}
                      Hours of Hacking <br /> Including Expert Sessions & Talks
                    </strong>
                  </div>
                </div>
               
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
